import './firebase'
import { createContext, useEffect, useState } from 'react'

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import PageLoader from '../components/PageLoader'
import { getData } from './http'
import { useToast } from '@chakra-ui/react'

export const auth = getAuth()

const provider = new GoogleAuthProvider()

export const loginWithGoogle = () => signInWithPopup(auth, provider)

export const logout = () => auth.signOut()

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const toast = useToast()
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          await getData({ url: 'health' })
        }
        setCurrentUser(user)
        setPending(false)
      } catch (error) {
        toast({
          title: 'Access denied!',
          description: "This user doesn't have access to this portal",
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        })
        auth.signOut()
        setPending(false)
      }
    })
  }, [])

  return pending ? (
    <PageLoader />
  ) : (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
