import { Avatar, Box, Center, Icon, Image, Text, VStack, Button } from '@chakra-ui/react'
import Logo from '../images/logo.png'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MENU_ITEMS } from '../services/constants'
import { AuthContext, logout } from '../services/auth'
import { MdLogout } from 'react-icons/md'

const MenuIcon = ({ link, title, icon, onClick }) => {
  const linkJsx = (
    <Center height={120} borderBottom="1px solid white" cursor="pointer" onClick={onClick}>
      <VStack spacing={1} color="gray.600">
        <Icon as={icon} boxSize="50" />
        <Text fontSize="xs" textTransform="uppercase" fontWeight="600">
          {title}
        </Text>
      </VStack>
    </Center>
  )

  return link ? <Link to={link}>{linkJsx}</Link> : linkJsx
}

const isDev = process.env.NODE_ENV === 'development'

const Navigation = () => {
  const { currentUser } = useContext(AuthContext)
  const { displayName, photoURL } = currentUser || { displayName: 'Dev' }

  return (
    <VStack background="gray.100" width={120} height="100%" justifyContent="space-between">
      <Box>
        <Center height={120}>
          <Link to="/">
            <Image src={Logo} width={100} />
          </Link>
        </Center>
        <Center height={120} borderTop="1px solid white" borderBottom="1px solid white">
          {isDev ? (
            <Center background="blue.300" textColor="white" h={20} w={20} borderRadius="full">
              Dev
            </Center>
          ) : (
            <Avatar
              referrerPolicy="no-referrer"
              loading="eager"
              size="lg"
              name={displayName}
              src={photoURL}
            />
          )}
        </Center>
        {MENU_ITEMS.map((item) => !item.disabled && <MenuIcon key={item.key} {...item} />)}
      </Box>
      <Center height={16}>
        <Button leftIcon={<MdLogout />} size="sm" variant="ghost" onClick={logout}>
          Logout
        </Button>
      </Center>
    </VStack>
  )
}

export default Navigation
