import { getAuth } from 'firebase/auth'
import { API_BASE_URL } from './constants'

export const getAccessToken = () => {
  try {
    const auth = getAuth()
    const token = auth?.currentUser?.accessToken

    return {
      Authorization: `Bearer ${token}`,
    }
  } catch (error) {
    console.log('Access token not found', error)
    return {}
  }
}

/** Get data from a http(s) endpoint */
export const getData = async ({ url, options }) => {
  const response = await fetch(`${API_BASE_URL}/${url}`, {
    headers: {
      ...getAccessToken(),
    },
    ...options,
  })

  if (response.status !== 200) throw Error(response)

  return response.json()
}

/** Post data to a http(s) endpoint */
export const postData = async ({ url, body, options }) => {
  const response = await fetch(`${API_BASE_URL}/${url}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...getAccessToken(),
    },
    ...options,
  })

  if (response.status !== 200) throw Error(response)

  return response.json()
}

/** Put data to a http(s) endpoint */
export const putData = async ({ url, body, options }) => {
  const response = await fetch(`${API_BASE_URL}/${url}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', ...getAccessToken() },
    ...options,
  })

  if (response.status !== 200) throw Error(response)

  return response.json()
}

/** Delete data to a http(s) endpoint */
export const deleteData = async ({ url, options, body }) => {
  const response = await fetch(`${API_BASE_URL}/${url}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json', ...getAccessToken() },
    ...options,
  })

  if (response.status !== 200) throw Error(response)

  return response.json()
}
