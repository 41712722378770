import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import { AuthProvider } from './services/auth'
import RequireAuth from './components/RequireAuth'
import { MENU_ITEMS } from './services/constants'

const App = () => {
  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            {MENU_ITEMS.map(({ link, key, component }) => (
              <Route key={key} path={link} element={<RequireAuth>{component}</RequireAuth>} />
            ))}
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App
