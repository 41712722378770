import { initializeApp } from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDZGAqgJFWSyz-j0JXb_FabdWDGGAC5b60',
  authDomain: 'shutterfly-cms.firebaseapp.com',
  projectId: 'shutterfly-cms',
  storageBucket: 'shutterfly-cms.appspot.com',
  messagingSenderId: '543979286393',
  appId: '1:543979286393:web:9194dbb09a0675944749a8',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
