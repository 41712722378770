import {
  Button,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  SkeletonCircle,
  SkeletonText,
  IconButton,
  useToast,
  Alert,
  AlertIcon,
  Image,
  Progress,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { BiCollapse, BiExpandAlt, BiPhotoAlbum } from 'react-icons/bi'
import { BsChevronLeft } from 'react-icons/bs'
import {
  MdAdd,
  MdAllInbox,
  MdArrowLeft,
  MdCancel,
  MdChevronLeft,
  MdChevronRight,
  MdDelete,
  MdEdit,
  MdExpand,
  MdExpandMore,
  MdReorder,
  MdSave,
  MdUpload,
} from 'react-icons/md'
import Page from '../components/Page'
import SFAlert from '../components/SFAlert'
import { API_BASE_URL } from '../services/constants'
import { deleteData, getAccessToken, getData, postData, putData } from '../services/http'
import { resizeImageUrl } from '../services/utils'

const Album = ({ albumId, refresh, setSelectedAlbum, albumDetails, expandedDefaultValue }) => {
  const { name, description } = albumDetails
  const inputRef = React.useRef()
  const toast = useToast()
  const [uploadLoading, setUploadLoading] = useState(-1)
  const [selectedPhotos, setSelectedPhotos] = useState({})
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deletePhotosLoading, setDeletePhotosLoading] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [reorderMode, setReorderMode] = useState(false)
  const [reorderSaveLoading, setReorderSaveLoading] = useState(false)

  const [photos, setPhotos] = useState([])

  useEffect(() => {
    setPhotos(albumDetails.photos)
  }, [albumDetails.photos])

  const numSelectedPhotos = useMemo(
    () => Object.values(selectedPhotos)?.filter((value) => value)?.length,
    [selectedPhotos]
  )

  // Get single selected photo for reordering
  const selectedPhotoId = useMemo(() => Object.keys(selectedPhotos)[0], [selectedPhotos])

  const handleUpload = async (event) => {
    try {
      const files = event.target.files
      setUploadLoading(0)
      // setExpanded(true)

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const formData = new FormData()
          formData.append('file', files[i])
          formData.append('album', albumId)

          await fetch(`${API_BASE_URL}/photos`, {
            method: 'POST',
            body: formData,
            redirect: 'follow',
            headers: {
              ...getAccessToken(),
            },
          })
          setUploadLoading(Math.floor(((i + 1) / files.length) * 100))
        }

        refresh()
      }
    } catch (error) {
      console.log('error', error)
      toast({
        title: 'Photos could not be saved',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      })
    } finally {
      setUploadLoading(-1)
    }
  }

  const deleteHandler = async () => {
    setDeleteLoading(true)
    try {
      await deleteData({ url: `album?albumId=${albumId}` })
      refresh()
    } catch (error) {
      toast({
        title: 'There was an expected error!',
        description: 'We could not delete this album',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      })
    } finally {
      setDeleteLoading(false)
      setOpenDeleteDialog(false)
    }
  }

  const selectAllPhotos = () => {
    if (allSelected) {
      setSelectedPhotos({})
      setAllSelected(false)
    } else {
      setSelectedPhotos(
        photos.reduce((acc, { publicId }) => {
          acc[publicId] = true
          return acc
        }, {})
      )
      setAllSelected(true)
    }
  }

  const deletePhotosHandler = async () => {
    setDeletePhotosLoading(true)
    try {
      await deleteData({
        url: `photos`,
        body: {
          albumId,
          publicIds: Object.keys(selectedPhotos).filter((publicId) => selectedPhotos[publicId]),
        },
      })
      setSelectedPhotos({})
      setAllSelected(false)
      refresh()
    } catch (error) {
      toast({
        title: 'There was an expected error!',
        description: 'We could not delete this album',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      })
    } finally {
      setDeletePhotosLoading(false)
    }
  }

  const handleReorder = (direction = 'right') => {
    setPhotos((currState) => {
      const curr = [...currState]

      const index = curr.findIndex((photo) => photo.publicId === selectedPhotoId)

      const nextPosition =
        direction === 'right'
          ? index === curr.length - 1
            ? 0
            : index + 1
          : index === 0
          ? curr.length - 1
          : index - 1

      // Swap items in these positions
      const temp = curr[index]
      curr[index] = curr[nextPosition]
      curr[nextPosition] = temp

      return curr
    })
  }

  const handleReorderSave = async () => {
    try {
      setReorderSaveLoading(true)

      await putData({
        url: 'album',
        body: { ...albumDetails, photos, id: albumId },
      })

      setSelectedAlbum(false)
      setReorderMode(false)
      setSelectedPhotos({})
      refresh()
    } catch (error) {
      console.log('error', error)

      toast({
        title: 'There was an expected error!',
        description: 'This album could not be saved',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      })
    } finally {
      setReorderSaveLoading(false)
    }
  }

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.200',
        width: '100%',
        p: 3,
        borderRadius: 'md',
        '&:hover': {
          '& .controls': {
            visibility: 'visible',
          },
        },
      }}
    >
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="500">
          {name}
        </Text>
        <HStack spacing={2} className="controls" visibility={uploadLoading < 0 && 'hidden'}>
          <Button
            leftIcon={uploadLoading > -1 ? null : <MdUpload />}
            size="sm"
            onClick={() => inputRef.current.click()}
          >
            {uploadLoading < 0 ? (
              'Upload Photos'
            ) : (
              <Box width={70}>
                <Progress
                  isIndeterminate={uploadLoading === 0}
                  colorScheme="blue"
                  value={uploadLoading}
                />
              </Box>
            )}
          </Button>
          {photos?.length > 0 && (
            <Button
              leftIcon={<MdReorder />}
              size="sm"
              onClick={() => {
                setSelectedPhotos({})
                setReorderMode(true)
              }}
            >
              Reorder Photos
            </Button>
          )}

          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputRef}
            onChange={handleUpload}
            multiple
          />
          <IconButton
            icon={<MdEdit />}
            size="sm"
            onClick={() =>
              setSelectedAlbum({
                id: albumId,
                details: albumDetails,
              })
            }
          />
          <IconButton icon={<MdDelete />} size="sm" onClick={() => setOpenDeleteDialog(true)} />
        </HStack>
      </HStack>

      <Text color="gray.500" fontSize="sm" mt={2}>
        {description}
      </Text>

      {(reorderMode || numSelectedPhotos > 0) && (
        <Alert size="sm" mt="3" fontSize="sm" colorScheme="gray.50" borderRadius="md">
          <HStack justifyContent="space-between" width="100%">
            <Text fontSize="sm">
              {reorderMode
                ? numSelectedPhotos < 1
                  ? 'Select a photo to change order'
                  : ''
                : `${numSelectedPhotos} photo ${numSelectedPhotos > 1 && 's'} selected`}
            </Text>

            <HStack spacing={2}>
              {reorderMode ? (
                <>
                  <IconButton
                    icon={<MdChevronLeft />}
                    size="sm"
                    onClick={() => handleReorder('left')}
                    disabled={numSelectedPhotos < 1}
                  />
                  <IconButton
                    icon={<MdChevronRight />}
                    size="sm"
                    onClick={() => handleReorder('right')}
                    disabled={numSelectedPhotos < 1}
                  />
                  <Button
                    leftIcon={<MdSave />}
                    size="sm"
                    disabled={numSelectedPhotos < 1}
                    onClick={handleReorderSave}
                    isLoading={reorderSaveLoading}
                  >
                    Save Order
                  </Button>
                  <Button
                    leftIcon={<MdCancel />}
                    size="sm"
                    onClick={() => {
                      setSelectedPhotos({})
                      setReorderMode(false)
                      setPhotos(albumDetails.photos)
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button leftIcon={<BiPhotoAlbum />} size="sm" onClick={selectAllPhotos}>
                    {allSelected ? 'De-select' : 'Select'} All
                  </Button>
                  <Button
                    leftIcon={<MdDelete />}
                    size="sm"
                    colorScheme="red"
                    onClick={deletePhotosHandler}
                    isLoading={deletePhotosLoading}
                  >
                    Delete {allSelected && 'all'} {numSelectedPhotos} photo
                    {numSelectedPhotos > 1 && 's'}
                  </Button>
                </>
              )}
            </HStack>
          </HStack>
        </Alert>
      )}

      {photos?.length > 0 && (
        <Box mt="4">
          {photos.map(({ url, publicId }) => (
            <Image
              key={publicId}
              src={resizeImageUrl(url)}
              height="190px"
              width="190px"
              objectFit="cover"
              display="inline"
              cursor="pointer"
              mr={3}
              sx={
                selectedPhotos[publicId] && {
                  display: 'inline',
                  border: '2px solid',
                  p: 1,
                  borderColor: 'blue.500',
                  opacity: 0.6,
                  cursor: 'pointer',
                  transition: '0.3s',
                }
              }
              onClick={() =>
                setSelectedPhotos((curr) =>
                  reorderMode
                    ? { [publicId]: !curr[publicId] }
                    : { ...curr, [publicId]: !curr[publicId] }
                )
              }
            />
          ))}
        </Box>
      )}
      <SFAlert
        isOpen={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSubmit={deleteHandler}
        heading={`Delete Album - ${name}`}
        description={
          <>
            Are you sure? Deleting an album also deletes all uploaded photos. This action is
            irreversible.
          </>
        }
        isLoading={deleteLoading}
      />
    </Box>
  )
}

const Albums = () => {
  const [selectedAlbum, setSelectedAlbum] = useState(null)
  const [albums, setAlbums] = useState([])
  const [loading, setLoading] = useState(true)
  const toast = useToast()

  const fetchAlbums = async (silent) => {
    try {
      if (!silent) setLoading(true)
      const result = await getData({ url: 'photos' })
      if (Array.isArray(result)) setAlbums(result)
    } catch (error) {
      console.log('error', error)
      toast({
        description: 'Albums could not be fetched',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
      setAlbums([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAlbums()
  }, [])

  const AddAlbum = ({ onClose, isOpen }) => {
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (selectedAlbum) setForm(selectedAlbum === 'NEW' ? {} : selectedAlbum.details)
    }, [selectedAlbum])

    const handleFormSubmission = async (evt) => {
      evt.preventDefault()

      try {
        setLoading(true)

        // Create Album / Edit Album
        if (selectedAlbum === 'NEW') {
          await postData({ url: 'albums', body: form })
        } else {
          await putData({
            url: 'album',
            body: { ...form, id: selectedAlbum.id },
          })
        }

        setLoading(false)
        setSelectedAlbum(false)
        fetchAlbums()
      } catch (error) {
        console.log('error', error)

        toast({
          title: 'There was an expected error!',
          description: 'This album could not be saved',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        })

        setLoading(false)
      }
    }

    return (
      <Modal isOpen={isOpen} size="2xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedAlbum === 'NEW' ? 'Add New Album' : 'Edit Album Details'}
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody py={0} pb={2}>
            <VStack spacing="4">
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  type="name"
                  placeholder="What do you want to call this album?"
                  value={form?.name || ''}
                  onChange={(evt) => setForm((curr) => ({ ...curr, name: evt.target.value }))}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="text">Description</FormLabel>
                <Textarea
                  type="text"
                  placeholder="Describe what this is about."
                  height={120}
                  value={form?.description || ''}
                  onChange={(evt) =>
                    setForm((curr) => ({ ...curr, description: evt.target.value }))
                  }
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={loading}
              onClick={handleFormSubmission}
              disabled={!form.name}
            >
              Save
            </Button>
            <Button onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Page heading="Albums">
      <HStack justifyContent="space-between" alignItems="center">
        <Text color="gray.500">Manage albums & photos displayed on the website.</Text>
        <Button size="sm" leftIcon={<MdAdd />} onClick={() => setSelectedAlbum('NEW')}>
          Add Album
        </Button>
      </HStack>
      <AddAlbum isOpen={!!selectedAlbum} onClose={() => setSelectedAlbum(null)} />
      {loading && (
        <VStack spacing={10} mt={5}>
          <Box bg="white" width="100%">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        </VStack>
      )}
      {!loading && albums.length < 1 && (
        <Alert status="warning" mt={5}>
          <AlertIcon />
          No albums added
        </Alert>
      )}
      <VStack spacing="4" mt={5}>
        {albums.map(({ id, details }, idx) => (
          <Album
            key={id}
            albumId={id}
            albumDetails={details}
            refresh={fetchAlbums}
            setSelectedAlbum={setSelectedAlbum}
            expandedDefaultValue={true}
          />
        ))}
      </VStack>
    </Page>
  )
}

export default Albums
