import { Button, Center, Image, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import Logo from '../images/logo.png'
import { BsGoogle } from 'react-icons/bs'
import { auth, loginWithGoogle } from '../services/auth'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()

  const handleGoogleLogin = async () => {
    try {
      const result = await loginWithGoogle()
      console.log('result', result)

      window.location = '/'
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (auth.currentUser) navigate('/')
  }, [])

  return (
    <Center bg="gray.100" height="100vh">
      <VStack px={4} py={10} color="black" bg="white" width="md" borderRadius="md" spacing={4}>
        <Image src={Logo} alt="Shutterfly Studio" height={100} />
        <Text fontSize="md" color="gray.600">
          Content Management System
        </Text>
        <Button colorScheme="blue" size="lg" rightIcon={<BsGoogle />} onClick={handleGoogleLogin}>
          Login with Google
        </Button>
      </VStack>
    </Center>
  )
}

export default Login
