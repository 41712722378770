import { BsEmojiHeartEyes } from 'react-icons/bs'
import { MdSettings, MdPhoto } from 'react-icons/md'
import { BiWorld } from 'react-icons/bi'
import Testimonials from '../pages/Testimonials'
import Settings from '../pages/Settings'
import Albums from '../pages/Albums'

export const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5001/shutterfly-cms/europe-west2/api'
    : 'https://europe-west2-shutterfly-cms.cloudfunctions.net/api'

export const MENU_ITEMS = [
  {
    key: 'albums',
    link: '/',
    title: 'Albums',
    icon: MdPhoto,
    component: <Albums />,
  },
  {
    key: 'testimonials',
    link: '/testimonials',
    title: 'Testimonials',
    icon: BsEmojiHeartEyes,
    component: <Testimonials />,
  },
  {
    key: 'settings',
    link: '/settings',
    title: 'Settings',
    icon: MdSettings,
    component: <Settings />,
    disabled: true,
  },
  {
    key: 'deploy',
    title: 'Deploy',
    icon: BiWorld,
  },
]
