import { HStack, Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { AuthContext } from '../services/auth'
import Navigation from './Navigation'

const RequireAuth = ({ children }) => {
  const { currentUser } = useContext(AuthContext)

  let location = useLocation()

  if (process.env.NODE_ENV !== 'development' && !currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return (
    <HStack height="100vh" alignItems="flex-start">
      <Navigation />
      <Box flex="1" height="100vh" overflowY="auto">
        {children}
      </Box>
    </HStack>
  )
}

export default RequireAuth
