import {
  Button,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  SkeletonCircle,
  SkeletonText,
  Divider,
  IconButton,
  Stack,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md'
import SFAlert from '../components/SFAlert'
import Page from '../components/Page'
import { deleteData, getData, postData, putData } from '../services/http'

const Testimonial = ({ name, text, location, onEdit, onDelete }) => {
  return (
    <VStack
      py={3}
      spacing={2}
      alignItems="flex-start"
      sx={{
        '&:hover': {
          '& .controls': {
            visibility: 'visible',
          },
        },
      }}
    >
      <HStack justifyContent={'space-between'} width="100%" pr={2}>
        <Text fontWeight="600">
          {name}
          {location && <>, {location}</>}
        </Text>
        <HStack spacing={2} className="controls" visibility="hidden">
          <IconButton icon={<MdEdit />} size="sm" onClick={onEdit} />
          <IconButton icon={<MdDelete />} size="sm" onClick={onDelete} />
        </HStack>
      </HStack>
      <Text mt={0}>{text}</Text>
      <Divider />
    </VStack>
  )
}

const Testimonials = () => {
  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [testimonials, setTestimonials] = useState([])
  const [deleteId, setDeleteId] = useState(null)
  const [deleteCustomerName, setDeleteCustomerName] = useState(null)
  const [selectedTestimonial, setSelectedTestimonial] = useState(null)
  const toast = useToast()

  const fetchTestimonials = async (silent) => {
    try {
      if (!silent) setLoading(true)
      const result = await getData({ url: 'testimonials' })
      if (Array.isArray(result)) setTestimonials(result)
    } catch (error) {
      console.log('error', error)
      toast({
        description: 'Testimonials could not be fetched',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      })
      setTestimonials([])
    } finally {
      setLoading(false)
    }
  }

  const deleteHandler = async () => {
    setDeleteLoading(true)
    try {
      await deleteData({ url: `testimonial?id=${deleteId}` })
      fetchTestimonials()
    } catch (error) {
      toast({
        title: 'There was an expected error!',
        description: 'We could not delete this testimonial',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      })
    } finally {
      setDeleteLoading(false)
      setDeleteId(null)
      setDeleteCustomerName(null)
    }
  }

  useEffect(() => {
    fetchTestimonials()
  }, [])

  const AddTestimonial = ({ isOpen, onClose }) => {
    const initialRef = React.useRef()
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (selectedTestimonial)
        setForm(selectedTestimonial === 'NEW' ? {} : selectedTestimonial.details)
    }, [selectedTestimonial])

    const handleFormSubmission = async (evt) => {
      evt.preventDefault()

      try {
        setLoading(true)

        // Create testimonial
        if (selectedTestimonial === 'NEW') {
          await postData({ url: 'testimonials', body: form })
        } else {
          await putData({
            url: 'testimonial',
            body: { ...form, id: selectedTestimonial.id },
          })
        }

        setSelectedTestimonial(false)
        fetchTestimonials()
      } catch (error) {
        toast({
          title: 'There was an expected error!',
          description: 'This testimonial could not be saved',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        })
      } finally {
        setLoading(false)
      }
    }

    return (
      <Modal initialFocusRef={initialRef} isOpen={isOpen} size="2xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedTestimonial === 'NEW' ? 'Add New Testimonial' : 'Edit Testimonial'}
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />

          <ModalBody py={0} pb={2}>
            <VStack spacing="4">
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  ref={initialRef}
                  id="name"
                  type="name"
                  placeholder="Who gave you this compliment?"
                  value={form?.name || ''}
                  onChange={(evt) => setForm((curr) => ({ ...curr, name: evt.target.value }))}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="location">Location</FormLabel>
                <Input
                  id="location"
                  type="location"
                  placeholder="Where about in the world do they live?"
                  value={form?.location || ''}
                  onChange={(evt) => setForm((curr) => ({ ...curr, location: evt.target.value }))}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="text">Testominial</FormLabel>
                <Textarea
                  id="text"
                  type="text"
                  placeholder="What lovely words did they say about your work?"
                  height={220}
                  value={form?.text || ''}
                  onChange={(evt) => setForm((curr) => ({ ...curr, text: evt.target.value }))}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={loading}
              onClick={handleFormSubmission}
              disabled={!(form.name && form.text)}
            >
              Save
            </Button>
            <Button onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Page heading="Testimonials">
      <HStack justifyContent="space-between" alignItems="center">
        <Text color="gray.500">Manage testimonials displayed on the website.</Text>
        <Button size="sm" leftIcon={<MdAdd />} onClick={() => setSelectedTestimonial('NEW')}>
          Add Testimonial
        </Button>
      </HStack>

      {!loading && testimonials.length < 1 && (
        <Alert status="warning" mt={5}>
          <AlertIcon />
          No testimonials added
        </Alert>
      )}

      <AddTestimonial isOpen={!!selectedTestimonial} onClose={() => setSelectedTestimonial(null)} />

      {loading && (
        <VStack spacing={10} mt={5}>
          <Box bg="white" width="100%">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        </VStack>
      )}

      {testimonials.map(({ id, details }) => (
        <Testimonial
          key={id}
          onEdit={() => setSelectedTestimonial({ id, details })}
          onDelete={() => {
            setDeleteId(id)
            setDeleteCustomerName(details.name)
          }}
          {...details}
        />
      ))}

      <SFAlert
        isOpen={!!deleteId}
        onClose={() => {
          setDeleteId(null)
          setDeleteCustomerName(null)
        }}
        onSubmit={deleteHandler}
        heading={`Delete Testimonial by ${deleteCustomerName}`}
        isLoading={deleteLoading}
      />
    </Page>
  )
}

export default Testimonials
