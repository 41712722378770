import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const Page = ({ children, heading }) => {
  return (
    <Box p={4}>
      {heading && (
        <Text fontWeight="medium" fontSize={22} mb={2} background="white" py={3}>
          {heading}
        </Text>
      )}
      {children}
    </Box>
  )
}

export default Page
