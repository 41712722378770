import { Center, Image, Spinner, VStack, Text } from '@chakra-ui/react'
import Logo from '../images/logo.png'
import React from 'react'

const PageLoader = () => {
  return (
    <Center height="100vh" background="gray.50">
      <VStack spacing={5} background="white" p={10} borderRadius="md">
        <Image src={Logo} alt="Shutterfly Studio" height="100px" />
        <Spinner size="md" color="blue.500" />
      </VStack>
    </Center>
  )
}

export default PageLoader
